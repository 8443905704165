.map-container {
  position: relative;
}

.map {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  display: flex;
}

.ndf-label {
  margin-bottom: 55px;
}

div.ndf-map-tooltip-wrapper {
  background-color: 'transparent';
  position: 'relative';
  overflow: 'visible';
  cursor: 'pointer';
  // border: 1px solid;
  width: 260px;
  height: 60px;
  margin-left: -130px;
  overflow: hidden;

  & > img {
    display: none;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ndf-map-tooltip {
  max-width: 260px;
  min-width: 100px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;

  &-option {
    margin-bottom: 5px;

    &-label {
      min-width: 62px;
      white-space: nowrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 8px);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-radius: 3px;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 12px solid rgba(255, 255, 255, 0.6);
  }
}
