@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.organization-toggles-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 11px;
  grid-row-gap: 10px;
  padding-bottom: 44px;
}

.organization-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $graysoft;
  border-radius: 12px;

  padding: 15px 12px 15px 15px;

  height: 49px;
}

.organizations-search {
  .input-search {
    width: 360px;
  }
}

.cutoff-container {
  z-index: 100;
  // border-width: 1px;
  // border-style: solid;
  // border-radius: 12px;
  // height: 45px;
  & > .input-wrapper {
    padding: 0 20px;
  }
  &input {
    height: 45px;
  }
  & > .input-wrapper-small {
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    border-color: #dce7f1;
    height: 45px;
    &.input-error {
      border-color: $red;
    }
  }
}

.cutofftime-input-wrapper {
  .input-container {
    width: 360px;
  }
}

.dnf-spinner-overlay {
  z-index: 100;
}
