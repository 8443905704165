.sizes {
  display: flex;
  flex-direction: row;
  // align-items: center;
  min-height: 36px;
  justify-content: space-around;
  background-color: #f7f7f7;
  border-radius: 8px;
  border: 2px solid #f7f7f7;
  flex-wrap: wrap;

  &-size {
    display: flex;
    flex-grow: 1;
    flex-basis: 33.333334%;
    border-radius: 6px;
    height: 32px;
    font-family: 'PrimarySemiBold';
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #6d6d6d;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 21px;
    cursor: pointer;
    transition: 300ms;

    &.active {
      background-color: #ffffff;
      color: #1d1d1d;
      box-shadow: 0px 6px 18px 0px #0000000d;
    }
  }
}
