.custom-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 12px 0 12px 0;
  pointer-events: none;
}

html[dir='rtl'] {
  .custom-placeholder {
    right: 0;
  }
}
