@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.tab-label {
  padding: 12px 20px;
  font-size: 18px;
  line-height: 21.6px;
  color: $dark;
  background-color: $graysoft;
  border-radius: 14px;
  position: relative;

  &__with_counter {
    position: relative;
    display: flex;

    & .counter {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
      font-weight: 600;
      z-index: 2;
      background: $red;
      min-width: 24px;
      height: 24px;
      border-radius: 666px;
      right: -10px;
      top: -10px;
      outline: white 3px solid;
    }
  }

  &:last-of-type {
    margin: 0 !important;
  }

  &.active {
    background-color: $mainblue;
    color: $white;
    // box-shadow: 0px 6px 18px rgba($mainblue, 0.3);
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      padding: 10px 15px;
      font-size: 16px;
      line-height: 19.2px;
      border-radius: 10px;

      &.active {
        box-shadow: none;
      }
    }
  }
}
