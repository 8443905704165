@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.label {
  margin: 0 9px;
  padding: 5px 10px 4px 12px;
  border-radius: 100px;
  font-size: 15px;
  letter-spacing: 1.25px;
  color: $gray5;
  background-color: $grayBack;
  text-transform: uppercase;
  font-weight: 500;

  &-grey {
    color: $gray5;
    background-color: $grayBack;
  }

  &-blue {
    color: $mainblue;
    background-color: $bluelight;
  }

  &-green {
    color: $green;
    background-color: $greenBack;
  }

  &-yellow {
    color: rgba(229, 171, 20, 1);
    background-color: rgba(255, 191, 26, 0.1);
  }

  &-red {
    color: $red;
    background-color: $redlight;
  }
}

.margin-15 {
  margin: 0 15px;
}
