.popover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transform: translate(-50%, -120%) !important;

  font-size: 14px;
  font-weight: 500;

  width: 165px;
  height: 46px;

  padding: 15px;

  /* White 60% */
  background: rgba(255, 255, 255, 0.6);

  /* Blur + shadow */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(24px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14px;

  margin-bottom: 10px;
}

.popover-button {
  .container {
    padding: initial;
  }
}
