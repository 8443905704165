@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.pagination {
  width: 100%;
  height: 76px;
  align-items: center;

  &__button {
    min-width: 38px;
    height: 38px;
    border-radius: 10px;
    border: 1px solid $gray;
    &:disabled {
      opacity: 0.5;
    }

    &:first-child,
    &:last-child {
      border: none;
    }

    &_active {
      background-color: $mainblue;
      color: $white !important;
    }

    &_hide {
      display: none;
    }
  }
}
