@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.dsp-organizations-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  color: $gray5;
  font-family: $font_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: $defaultLetterSpacingAdapted;
}
