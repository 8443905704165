@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.map-wrapper {
  position: relative;
  display: flex;
  flex: 1;
}

.map {
  outline: none;
}

.cluster {
  z-index: 1;
}

.gmnoprint {
  display: none;
}

img[alt='Google'] {
  display: none;
}

.map-controls-wrapper {
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: center;

  margin-bottom: 200px;
  margin-top: 100px;

  .map-control {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    color: $dark;
    font-size: 22px;
    border-radius: 16px;
    width: 48px;
    height: 48px;

    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(46px);
    transition: 0.2s;

    &:focus {
      opacity: 0.2;
    }
  }

  .bundled-control {
    & > *:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > *:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.horizontal-top {
    top: 15px;

    // .separator {
    //   width: 1px;
    //   height: 100%;
    //   background-color: $gray;
    // }

    .map-control {
      color: #000;
    }
  }
}

.pulse-label {
  background: transparent;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  z-index: -2;

  &:after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    background: rgba(66, 158, 255, 0.15);
    border: none;
    top: -20px;
    left: -19px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    // box-shadow: 0 0 1px 2px #2196f3;
    animation-delay: -0.1s;
  }
}

html[dir='rtl'] {
  .pulse-label:after {
    top: -20px;
    left: -6px;
  }
}

@keyframes pulsate {
  0%,
  100% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
