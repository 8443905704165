@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.delivery-zone {
  &__form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 25px 15px 0;
    backdrop-filter: blur(20px);
    background-color: $white;
    // background-color: rgba($graysoft, 0.6);
  }
}

html[dir='rtl'] {
  .delivery-zone__icon-back {
    transform: rotate(180deg);
  }
}
