@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.filter-dropdown-modal {
  width: 290px;
  height: 100%;

  /* White 80% */

  background: rgba(255, 255, 255, 0.9);
  /* Blur + shadow */

  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(24px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  .input-search {
    border: none;
  }

  &__values-wrapper {
    height: calc(100% - 45px);
    // min-height: 50px;
  }

  &__no-matches {
    font-family: $font_regular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: $defaultLetterSpacingAdapted;
    color: $gray5;
  }
}

.filter-dropdown-modal_search-input {
  min-width: auto;
}
.filter-dropdown-item {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: $font_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: $defaultLetterSpacingAdapted;
  color: $dark;

  cursor: pointer;

  &__icon {
    margin-right: 10px;
    margin-left: 10px;
    &.hidden {
      opacity: 0;
    }
  }
}

.scrollable-block.pos-relative .scrollable-block__scroll {
  position: relative !important;
  height: 100%;
  max-height: 240px;
}
