@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.picker-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 53px;
  box-sizing: border-box;
  padding: 15px;
  background-color: $white;
  border-bottom: 1px solid rgba($gray, 0.5);
  transition-duration: $animation_duration_md;
  cursor: pointer;
  &:hover {
    background-color: $bluelight;
  }
  &_unselected {
    visibility: hidden;
  }

  &_small {
    padding: 10px;

    .picker-option {
      min-height: 39px;
      &__text {
        font-family: $font_medium;
        font-size: 12px;
        line-height: 14.4px;
      }
    }
  }

  &__text {
    font-family: $font_semibold;
    font-size: 16px;
    line-height: 19.2px;
    color: $dark;

    &_with_mark {
      max-width: calc(100% - 87px);
    }
  }

  &__search {
    height: 53px;
    padding: 0;
    background-color: $white !important;
    border-bottom: none;

    &_small {
      height: 39px;
    }
  }
}
