@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

html[dir='rtl'] {
  .modal-content {
    .modal-close-icon {
      right: initial;
      left: 45px;
    }
  }
}

.modal {
  &-header {
    border-bottom: 2px solid $gray;
    display: flex;
    flex-direction: row;
  }
  &-main {
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    position: relative;
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  &-background {
    width: 100%;
    min-height: 100%;
    //   max-height: 870px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #ffffff;
    border-radius: 40px;

    // position: absolute;
    // top: 0;
    &-modal {
      max-width: 1040px;
    }
  }
  &-content {
    width: 80%;
    height: 80%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;

    .modal-close-icon {
      position: absolute;
      font-size: 14px;
      color: #6d6d6d;
      top: 30px;
      right: 45px;
      z-index: 1003;
      cursor: pointer;
      padding: 5px;
      margin: 0;
      display: flex;

      i {
        font-size: 14px;
        display: flex;

        &:before {
          margin: 0;
        }
      }
    }
  }

  .modal-pane {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    &::before {
      content: '';
      display: flex;
      border-radius: 2px;
      width: 41px;
      height: 4px;
      background-color: #f1f1f1;
    }
  }
  .modal-screen {
    animation-name: slideup;
    animation-duration: 300ms;
    background-color: #ffffff;
  }

  @media (max-width: 1023px) {
    .modal-content {
      top: 50px !important;
      width: 100%;
      max-width: 100% !important;
      max-height: 100% !important;
      height: calc(100% - 50px) !important;
      animation-name: slide_in_down !important;
      animation-duration: 300ms !important;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.175);
      border-radius: 30px 30px 0px 0px !important;
      overflow: hidden;
      &.modal_pane {
        padding-top: 34px;
      }
    }
  }
}
