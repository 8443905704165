@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.dsps-dsp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  // height: 118px;
  margin: 0 0 0 0;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 14px;
  border: 2px solid transparent;

  &.active {
    border: 2px solid $mainblue;
    border-radius: 14px;
  }

  &:last-of-type {
    margin: 0;
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      padding: 10px;
    }
  }

  &.selected {
    background-color: $bluelight;
    // box-shadow: 0px 6px 18px rgba($dark, 0.05);
  }

  &-0.blink {
    background-color: $mainblue;
  }
  &-1.blink {
    background-color: $red;
  }
  &-2.blink {
    background-color: $green;
  }
  &-0.blink,
  &-1.blink,
  &-2.blink {
    .dsps-dsp-subtitle {
      color: $white;
    }
    .dsps-dsp-text {
      color: $white;
    }
  }

  &.blink {
    animation-name: blink;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;

    .dsps-dsp-text {
      animation-name: blinkText;
    }
    .dsps-dsp-subtitle {
      animation-name: blinkSubtitle;
      animation-timing-function: ease-in;
    }
    .dsps-dsp-subtitle,
    .dsps-dsp-text {
      animation-duration: 1.5s;
      animation-direction: alternate-reverse;
      animation-iteration-count: infinite;
      // animation-timing-function: ;
    }
  }
  &-info {
    width: 100%;
  }
  &-text {
    color: $gray4;
    font-size: 12px;

    &__delivery {
      letter-spacing: 0.12em;
    }
  }
  &-subtitle {
    color: $dark;
  }

  &-working-green {
    color: $green;
  }

  &-working-red {
    color: $red;
  }
}

@keyframes blink {
  0% {
    // box-shadow: 0px 10px 20px rgba($mainblue, 0.3);
    // box-shadow: 0px 10px 20px rgba($white, 0.3);
  }
  100% {
    background-color: $white;
    // box-shadow: 0px 10px 20px rgba($mainblue, 0.3);
  }
}

@keyframes blinkSubtitle {
  0% {
  }
  100% {
    // color: $graysoft;
    color: $dark;
  }
}

@keyframes blinkText {
  0% {
  }
  100% {
    // color: $graysoft;
    color: $gray2;
  }
}

// @media screen and (max-width: $breakpoints_xl) {
//   .dsps-dsp {
//     &.selected {
//       //   box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
//     }
//   }
// }
