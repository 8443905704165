.delivery-zone {
  &-map {
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    position: relative;
  }
}

html[dir='rtl'] {
  .delivery-zone-map {
    border-radius: 0 20px 20px 0;
  }
}
