@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.filter-dropdown-calendar-modal {
  width: 337px;
  height: 418px;

  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(46px);
  border-radius: 16px;

  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.selected-dates-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  & .filter-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    color: $dark;

    &.light {
      color: $gray5;
    }

    &__title {
      font-family: $font_regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &__value {
      font-family: $font_bold;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: $defaultLetterSpacingAdapted;
    }
  }
}

.filter-dropdown-calendar {
  & .react-calendar {
    &__tile {
      margin: 3px 0;
      border-radius: 0;

      &--active {
        background-color: rgba(66, 158, 255, 0.1);
        &:enabled:hover {
          background-color: rgba(66, 158, 255, 0.1);
        }
      }

      &--rangeStart {
        background-color: #429eff !important;
        color: white !important;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      &--rangeEnd {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        opacity: 1;
      }
    }
  }
}
