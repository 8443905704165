.status-label {
  background: #f7f7f7;
  padding: 5px 10px;
  margin-right: 10px;
}

.timeline {
  display: flex;
  flex-direction: column;

  &_item {
    display: grid;
    grid-template-columns: 100px 50px 1fr;
    flex: 1;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: center;

    .line_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-left: 35px;
      margin-right: 30px;
      position: relative;
      flex-basis: 20px;

      &.last {
        position: relative;
        top: -18px;
      }

      .circle {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        min-width: 18px;
        min-height: 18px;
        height: 18px;
        width: 18px;
        border-radius: 666px;
        z-index: 2;

        background: #429eff;
        flex: 1;

        &.danger {
          width: 34px;
          height: 34px;
          background: #ff7676;
        }

        &.last {
          position: initial;
          margin-top: 2px;
          background: white;
          border: 2px solid #dce7f1;
          box-sizing: border-box;
        }
      }

      .line {
        width: 2px;
        background: #429eff;
        flex: 3;
        min-height: 70px;

        &.last {
          margin-top: 5px;
          background: #dce7f1;
          min-height: 56px;
        }

        &.danger-prev {
          background: linear-gradient(180deg, #429eff 0%, #ff7676 100%);
        }

        &.danger {
          transform: rotate(-180deg);
          background: linear-gradient(180deg, #429eff 0%, #ff7676 100%);
        }
      }
    }

    .leading {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex: 1;
      flex-basis: 100px;
    }

    .trailing {
      display: flex;
      flex-direction: row;
      height: min-content;
    }
  }
}
