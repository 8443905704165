@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.header-title__title {
  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      font-size: 32px !important;
      line-height: 38.4px !important;
    }
  }
}
