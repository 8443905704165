@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.error-modal {
  max-width: 716px;
  max-height: 390px;

  & .modal-main-content {
    justify-content: center;
    align-items: center;
  }

  & .error-warring-icon {
    color: $red;
    font-size: 92px;
    text-align: center;
    padding: 17px 0 0 0;
  }

  & .error-title {
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
    color: $dark;
    padding: 30px 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.025em;
  }

  & .error-close-button {
    margin: 39px 0 0 0;
    max-width: 252px;
    height: 60px;
    color: $dark;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.025em;
    border-radius: 16px;
  }
}
