@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.delivery-zone-item {
  $root: &;
  padding: 15px 10px;
  border: 1px solid transparent;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 0;
  // transition: background-color 0.6s;
  position: relative;
  border-radius: 10px;
  background-color: $white;
  touch-action: pan-y;

  &:hover {
    border: 1px solid $mainblue;
  }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -5px;
  //   left: 0;
  //   background-color: $gray;
  //   width: 100%;
  //   height: 2px;
  // }

  &:first-of-type {
    margin-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 2px;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  &__name {
    max-width: 165px;
  }

  &__priority {
    border-radius: 50%;
    background-color: $graysoft;
    font-size: 16px;
    font-family: $font_medium;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {
    // background-color: $white;
    border-color: $mainblue;

    &::before {
      display: none;
    }

    & + #{$root} {
      &::before {
        display: none;
      }
    }
  }

  &__icon {
    &-drag {
      transform: rotate(90deg);

      &-wrapper {
        width: 14px;
        height: 14px;
        cursor: grab;
      }
    }
  }

  &__drag_active {
    touch-action: pan-y;
    box-shadow: 0px 6px 20px rgba($dark, 0.08);
    background-color: $white !important;
    border: none !important;
    backdrop-filter: blur(24px);
    z-index: 1;
    cursor: grabbing;

    & .delivery-zone-item__icon-drag-wrapper {
      cursor: grabbing;
    }
  }

  &__drag-clone {
    background-color: rgba($bluelight, 0.6);
  }

  &__droppable {
    background-color: rgba($mainblue, 0.3);
  }
}
