@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.dsps-dsp-status {
  letter-spacing: 2px;
}

.dsp-list {
  max-width: 300px;
  width: 100%;

  &__scrollbar {
    height: 100%;
    /* W3C standard
   only for Firefox */
    & {
      scrollbar-width: thin;
      scrollbar-color: $gray white;
    }

    /* for Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      border-radius: 2px;
      border: none;
    }
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      max-width: 240px;
    }
  }
}
