@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.scrollable-block {
  height: 100%;
  overflow-y: auto;
  position: relative;

  &_horizontal {
    overflow-y: hidden;
    overflow-x: visible;
  }

  &__scroll {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    &_horizontal {
      flex-direction: row;
    }

    // WARNING !!! this styles only for scroll and only for this
    &_small {
      /* W3C standard
       only for Firefox */
      scrollbar-width: thin;
      scrollbar-color: rgba(224, 224, 224, 0.7) rgba(white, 0);
      /* for Chrome/Edge/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(224, 224, 224, 0.7);
        backdrop-filter: blur(80px);
        border-radius: 2.5px;
        border: none;
      }
    }

    // WARNING !!! this styles only for scroll and only for this
    &_normal {
      /* W3C standard
       only for Firefox */
      scrollbar-width: auto;
      scrollbar-color: rgba(176, 176, 176, 0.8) rgba(white, 0);
      /* for Chrome/Edge/Safari */
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(176, 176, 176, 0.8);
        border-radius: 5px;
        border: none;
      }
    }
  }
}
