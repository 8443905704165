@import 'taker-web-ui/dist/assets/styles/custom/variables.module';

.addinfo-popup {
  .header-info {
    gap: 10px;
  }

  .name-title {
    line-break: auto;
    max-width: 200px;
  }

  .dot {
    color: $gray2;
    font-size: 40px;
  }

  .info-row {
    gap: 50px;
    justify-content: space-between;
    padding: 0 10px;

    div {
      flex: 0 1;
      inline-size: 150px;
    }
  }

  table {
    border: none;
    table-layout: auto !important;
    border-collapse: collapse;
    width: 100%;
  }

  .tg {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .tg td {
    justify-content: center;
    // border-color:black;
    // border-style:solid;
    // border-width:1px;

    // font-family:Arial, sans-serif;
    // font-size:14px;

    overflow: hidden;
    word-break: normal;

    word-wrap: break-word;
    max-width: 150px;
  }

  .tg th {
    word-wrap: break-word;
    max-width: 150px;
    // border-color: black;
    // border-style: solid;
    // border-width: 0px;

    border: none;

    // font-family:Arial, sans-serif;
    // font-size: 14px;
    // font-weight:normal;

    overflow: hidden;
    word-break: normal;
    text-align: inherit;
  }

  .vertical-align-center-row {
    vertical-align: middle;
  }

  .tg {
    thead {
      tr {
        th {
          padding-bottom: 15px;
        }
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
}
