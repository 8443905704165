@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.sidebar {
  $sidebar: &;

  &_button {
    padding-left: 0;
    padding-right: 0;

    .sidebar_link-icon {
      color: $mainblue !important;
    }
  }
  &.open {
    #{$sidebar}-swap {
      width: initial !important;

      &::before {
        z-index: -1;
        animation-name: fadeInLeft;
        background-color: $white !important;
      }

      #{$sidebar}_link-icon {
        color: $gray2;
      }

      #{$sidebar}_link-text {
        color: $gray2;
      }

      &:hover {
        #{$sidebar}_link-icon {
          color: $dark !important;
        }

        #{$sidebar}_link-text {
          color: $dark;
        }
      }
    }
  }

  &-swap {
    background-color: $graysoft;
    border-radius: 16px;
    margin-bottom: 10px;
    margin-left: 10px;
    height: 48px;
    width: 48px;

    .icons-icon {
      margin-left: 5px !important;
      color: $gray2 !important;
    }

    &:hover {
      #{$sidebar}_link-icon {
        color: $dark !important;
      }
    }
  }
}

html[dir='rtl'] {
  .sidebar {
    &-swap {
      margin-left: 0;
      margin-right: 10px;

      .icons-icon {
        margin-left: 0;
        margin-right: 5px !important;
      }
    }
  }
}
// .link {
//   width: 260px;
//   height: 65px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   position: relative;
//   margin: 0;
//   padding: 20px;
//   color: $gray2;
//   z-index: 2;

//   &__menu {
//     display: none;
//   }

//   &:after {
//     content: '';
//     position: absolute;
//     top: 17.5px;
//     left: 0px;
//     width: 5px;
//     height: 30px;
//     border-radius: 0px 3px 3px 0px;
//   }

//   &_active {
//     color: $dark !important;

//     &:after {
//       background-color: $mainblue;
//     }
//   }

//   @media (min-width: $breakpoints_md) {
//     width: 280px;
//     height: 50px;
//     padding: 15px 20px;
//     margin: 0px 15px;
//     border-radius: 12px;

//     &::after {
//       content: none;
//     }

//     &__menu {
//       display: none;

//       &.link_close {
//         & {
//           display: flex;
//         }
//       }
//     }

//     &_active {
//       color: $white !important;
//       background-color: $mainblue;
//     }

//     &_close {
//       margin: 20px 0 0 0;
//       padding: 20px;
//       color: $mainblue;
//       background-color: $white;

//       &::after {
//         content: '';
//         top: 5px;
//         left: 0px;
//         width: 6px;
//         height: 40px;
//       }

//       .link_active > .link-icon {
//         color: $mainblue;

//         @media (min-width: $breakpoints_md) {
//           color: $mainblue;
//         }
//       }
//     }

//     &-text {
//       &_hidden {
//         display: none;
//       }
//     }
//   }
// }

// .link_active > .link-icon {
//   color: $mainblue;

//   @media (min-width: $breakpoints_md) {
//     color: $white;
//   }
// }

// .link_active.link_close > .link-icon {
//   color: $mainblue;
// }

// html[dir='rtl'] {
//   .link {
//     &:after {
//       right: 0px;
//       border-radius: 2px 0px 0px 2px;
//     }
//   }
// }
