@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.button-period {
  padding: 5px 10px;
  border-radius: 8px;
  background: $bluelight;
  color: $mainblue;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &.red {
    background: $redlight;
    color: $red;
    text-transform: uppercase;
  }

  &:hover {
    transition: 0.2s;
    opacity: 0.8;
  }
}
