.notification-bell {
  position: relative;

  &__point {
    min-width: 10px;
    height: 10px;
    box-sizing: content-box;
    border: 3px solid #fff;
    position: absolute;
    right: -2px;
    top: -5px;
    border-radius: 16px;
  }
}
