@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.sidebar {
  width: 305px;
  min-width: 305px;
  max-width: 305px;
  display: flex;
  flex-direction: column;
  // margin: 15px;
  padding: 40px 15px 0;
  transition: 700ms;
  overflow: hidden;
  &_overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0;
  }
  &.close {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    background-color: $white;
    padding: 40px 0 0;
  }
}
