@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.input {
  &-search {
    display: flex !important;
    align-items: center !important;
    min-width: 240px !important;
    height: 45px !important;
    background: rgba($dark, 0.03) !important;
    border-radius: 12px !important;
    padding: initial !important;
    border-width: 1px !important;

    &.border-gray {
      border: 1px solid $gray;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      padding: 0 35px 0 15px;
      border-radius: 12px !important;

      &::placeholder {
        color: $gray2;
      }
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: $gray2;
    }

    &::-moz-placeholder {
      color: $gray2;
    }

    &_with-close-icon {
      padding: 0 35px 0 15px;
    }
  }

  // @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
  //   & {
  //     width: 240px;
  //   }
  // }

  &-wrapper {
    position: relative;
    width: 100%;
  }

  &-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
  }

  &-close {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
  }
}

html[dir='rtl'] {
  .input {
    &-search {
      &_with-close-icon {
        padding: 0 15px 0 35px;
      }
    }

    &-spinner {
      right: auto;
      left: 0;
    }

    &-close {
      left: 0;
      right: initial;
    }
  }
}

//product list search input style
.search-product-list {
  .input {
    &-search {
      height: 44px;
      border: 1px solid $gray;
      background-color: $white;
    }
  }
}
