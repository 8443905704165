@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.order-info-modal {
  &.dnf-modal {
    max-width: 568px;
    max-height: 623px;

    .height-min {
      height: min-content;
    }

    .info-blocks {
      & > div {
        align-items: flex-start;
      }
    }

    .scrollable {
      overflow-y: auto;
    }

    .empty {
      max-height: 200px;
    }

    .dnf-modal-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $gray5;
    }

    .dnf-modal-values {
      margin: 15px 0 20px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .dnf-modal-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.005em;
      color: #1d1d1d;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
