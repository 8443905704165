@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.datetime-picker {
  &-modal {
    width: 295px;
    // height: 360px;
    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 18px;
    // position: absolute;
    .react-calendar__navigation {
      padding: 0 20px;
      &__label__labelText {
        font-size: 20px;
      }
    }
    .react-calendar {
      margin-top: 10px;
    }
  }
  &-input {
    height: 46px;
    position: relative;

    & .input-container {
      flex: 1;
    }

    & .input-wrapper {
      height: 46px;
    }

    input {
      height: 46px;
      padding-left: 44px;
      border: 1px solid $gray;
    }
  }
  &-calendar {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  &-times {
    height: 50px;
    max-height: 50px;
    position: relative;
    border: 1px solid;
    border-radius: 10px;
  }
  &-slots {
    // position: absolute;
    height: 50px;
    max-height: 50px;
    padding: 7.5px 0;
    position: relative;
    overflow-y: auto;

    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
  }
  &-time {
    height: 35px;
    max-height: 35px;
    text-align: center;
  }
}
