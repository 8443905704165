@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.requests-list {
  max-width: 304px;
  width: 304px;

  &__scrollbar {
    height: 100%;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: $gray white;

    /* for Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      margin-right: 20px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      border-radius: 2px;
      background-clip: padding-box;
    }
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      max-width: 304px;
    }
  }
}
