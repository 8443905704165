@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.order-info-modal {
  max-width: 1010px;
  max-height: 708px;

  .height-min {
    height: min-content;
  }

  .info-blocks {
    & > div {
      align-items: flex-start;
    }
  }
}

html[dir='rtl'] {
  .info-blocks {
    & > div {
      align-items: flex-end;
      text-align: end;
    }
  }
}

.info-icon {
  color: $gray5;
  margin-top: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-content: center;
}

.info-tooltip {
  width: 400px;
  text-align: left;
  margin-bottom: 20px;
  top: -75px;
}

.info-tooltip-bottom {
  width: 400px;
  text-align: left;
  margin-bottom: 20px;
  top: 25px;
}

.external-open-button-header {
  height: 20px;
}

.external-open-button-content {
  position: relative;
  top: -2px;
  float: right;
}

html[dir='rtl'] .external-open-button-content {
  float: left;
}
