@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.request-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 0 0 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 14px;
  border: 2px solid transparent;
  min-width: 300px;

  &.active {
    border: 2px solid $mainblue;
    border-radius: 14px;
  }

  &:last-of-type {
    margin: 0;
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      padding: 10px;
    }
  }

  &.selected {
    border: 2px solid #429eff;
    // box-shadow: 0px 6px 18px rgba($dark, 0.05);
  }
}
