@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.export-wrapper {
  position: absolute;
  left: 92px;
  bottom: 20px;
  display: flex;
  align-items: center;

  .tooltip {
    margin: 0 15px;
  }
}

.export-text-wrapper {
  display: flex;
  justify-content: center;
}

.export-tooltip {
  width: 350px;
  text-align: left;
}

.export-text {
  cursor: pointer;
  &__disabled {
    opacity: 0.5;
  }
}

.export-info-icon {
  color: $gray5;
  cursor: pointer;
}

.export-spinner {
  position: absolute;
}

.numbers-wrapper {
  position: absolute;
  right: 73px;
  bottom: 20px;
}
