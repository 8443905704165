.tabs {
  display: flex;
  flex-direction: row;

  &--tab {
    padding: 10px 15px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
  }
}
