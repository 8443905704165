@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.sidebar {
  $sidebar: &;

  &_link {
    z-index: 1;

    $link: &;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }

    &-content {
      z-index: 1;
    }

    &-icon {
      color: $gray2;
      max-width: 22px;
      transition: 400ms;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
    }

    &-text {
      color: $gray2;
      white-space: nowrap;
      transition: 400ms;
    }

    &.active {
      &::before {
        animation-name: fadeInLeft;
        background-color: $mainblue;
      }

      #{$link}-icon {
        color: white;
      }
      #{$link}-text {
        color: $white;
      }
    }
  }

  &.close {
    #{$sidebar}_link {
      $link: &;
      &:before {
        height: 40px;
        top: 8px;
        right: calc(100% - 6px);
        left: initial;
        border-radius: 0 3px 3px 0;
      }
      &-icon {
        color: $gray2;
      }
      &-text {
        color: $white;
      }
      &.active {
        #{$sidebar}_link-icon {
          color: $mainblue;
        }
      }
    }
  }
}

// html[dir='rtl'] {
//   .sidebar {
//     $sidebar: &;
//     &.close {
//       #{$sidebar}_link {
//         $link: &;
//         &:before {
//           right: initial;
//           left: calc(100% - 10px);
//         }
//       }
//     }
//   }
// }

html[dir='rtl'] {
  .sidebar {
    .sidebar_link {
      &.active {
        &::before {
          animation-name: fadeInLeftRtl;
        }
      }
    }
    &.close {
      .sidebar_link {
        $link: &;
        &:before {
          right: initial;
          left: calc(100% - 6px);
        }
      }
    }
  }
}
