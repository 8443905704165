@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.crumbs {
  display: flex;
  flex-direction: row;

  &__crumb {
    padding: 0 0 10px 0;
    margin: 0 20px 0 0;
    font-family: 'PrimarySemiBold';
    font-size: 16px;
    line-height: 19.2px;
    color: $gray3;
    cursor: pointer;
    transition-duration: 200ms;
    border-bottom: 2px solid $white;

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }

    &_active {
      color: $dark;
      border-bottom: 2px solid $mainblue;
    }
  }
}

html[dir='rtl'] {
  .crumbs {
    &__crumb {
      margin: 0 0 0 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
