@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.header {
  min-height: 95px;
  align-items: flex-end;

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      min-height: 69px;
    }
  }
}
