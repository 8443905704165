@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.user-info {
  width: 100%;
  // height: 89px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  // margin: 30px 15px 0 15px;
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.02);
  background-color: $white;

  &__avatar {
    width: 59px;
    height: 59px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.sidebar.close {
  .user-info {
    // display: none;
    position: absolute;
  }
}
