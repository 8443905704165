@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.time-dropdown-modal {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(46px);
  border-radius: 16px;
  padding: 20px;
  &__text {
    color: $graysoft;
  }
}
