@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.alt-input {
  position: relative;

  &.focused {
    .placeholder {
      // top: 5px;
      font-family: $font_bold;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      transform: translateY(-10px);
    }
  }

  input {
    display: flex;
    flex-grow: 1;
    height: 46px;
    width: 100%;
    padding: 14px 20px 0;
    font-family: $font_medium;
    font-size: 16px;
    line-height: 19.2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid $gray;
    border-radius: 12px;

    &.input-error {
      border-color: $red;
    }

    &:focus,
    &:hover {
      outline: none;
    }

    &:disabled {
      background-color: inherit;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .placeholder {
    position: absolute;
    left: 20px;
    top: 15px;
    font-family: $font_medium;
    font-size: 16px;
    line-height: 19.2px;
    color: $gray2;
    transition: 0.3s;
  }

  &__validation {
    &-block {
      padding: 8px 15px;
      margin: 0 0 5px 0;
      font-family: $font_medium;
      font-size: 16px;
      line-height: 19.2px;
      color: $white;
      background-color: $red;
      border-radius: 12px;

      &_bottom {
        // margin: 10px 0 15px 0;
        margin: 10px 0 0 0;
        font-family: $font_regular;
        font-size: 14px;
        line-height: 16.8px;
        color: $red;
      }
    }
  }
}

html[dir='rtl'] {
  .alt-input {
    .placeholder {
      left: initial;
      right: 20px;
    }
  }
}
