@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.filter-dropdown-btn {
  // width: 24px;
  padding: 0;
  height: 24px;
  width: 24px;
  max-height: 24px;
  min-height: 24px;
  border-radius: 6px;
  margin-left: 25px;
  line-height: 2px;

  background-color: $graysoft !important;

  &__clear {
    background-color: transparent !important;
    color: $dark;
    width: 12px;
    padding: 0;
    height: 12px;
    max-height: 24px;
    min-height: 24px;
    margin-right: 10px;
    border: none;

    &__wrapper {
      width: 12px;
      height: 12px;
      max-height: 24px;
      min-height: 24px;
      margin-left: 0px;
    }
  }

  &__transparent {
    background-color: transparent !important;
  }
}

.calendar-filter-bottom {
  text-align: center;
  padding-bottom: 20px;
}

.icon-clock {
  &::before {
    line-height: 1.2 !important;
  }
}
