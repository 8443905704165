@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.map-horizontal-controls-wrapper {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;

  height: 48px;
  border-radius: 12px;
  background: $white;

  .dnf-hint {
    display: none;
    position: absolute;
    width: max-content;
    padding: 15px;
    top: 55px;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(12px);
    border-radius: 14px;
  }

  .map-horizontal-control {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    color: $dark;
    font-size: 20px;
    border-radius: 10px;
    margin: 4px;
    width: 40px;
    height: 40px;

    background: $transparent;
    transition: 0.2s;

    &.active {
      background: $mainblue;
      color: $white;
    }

    &:hover {
      color: $dark;
      background: $graysoft;

      &.show-hint ~ .dnf-hint {
        display: block;
      }
    }

    &.disabled {
      background: $transparent;
      color: $dark;
      opacity: 0.5;
    }

    i {
      height: 20px;
    }
  }

  .map-horizontal-sepearator {
    width: 1px;
    height: auto;
    background: $gray;
    margin: 4px 0;
  }

  .hide {
    display: none;
  }
}

// @TODO: Move to anothe place when design will have standart
.justify-content-center > div {
  justify-content: center;
  margin: auto;
}
