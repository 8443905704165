@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.color-box {
  display: flex;
  // overflow: hidden;
  justify-content: center;
  align-items: center;
  transition: transform $animation_duration_md;
  position: relative;
  border: 0px solid $white;
  &.active {
    border: 2px solid $white;

    &::before {
      content: ' ';
      position: absolute;
      border-width: 2px;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      transition: transform $animation_duration_md;
      border: 1px solid $mainblue;
      border-radius: 15px;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}
