@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.scroll-list-wrapper {
  overflow-y: auto;
  position: relative;
}

.scroll-list-refreshing {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba($graysoft, 0.7);
  animation-name: fadein;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.scroll-list {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.scroll-to {
  position: absolute;
  left: 5px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid $gray;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(46px);
}

.scroll-to .icons-icon::before {
  margin: 0;
}

html[dir='rtl'] {
  .scroll-to {
    right: 5px;
    left: auto;
  }
}

@keyframes fadein {
  0% {
    background-color: rgba($graysoft, 0.1);
  }
  100% {
    background-color: rgba($graysoft, 0.6);
  }
}

.overlay_white {
  @keyframes fadein {
    0% {
      background-color: rgba($white, 0.1);
    }
    100% {
      background-color: rgba($white, 0.6);
    }
  }
}
