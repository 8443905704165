.app--version {
  position: fixed;
  bottom: 5px;
  right: 5px;
  text-transform: uppercase;
}

html[dir='rtl'] {
  .app--version {
    right: auto;
    left: 10px;
  }
}
