@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.switcher {
  &-wrapper {
    cursor: pointer;
    width: 50px;
    min-width: 50px;
    padding: 2px;
    border-radius: 30px;
    &.active {
      .switcher-circle {
        transform: translateX(20px);
      }
    }
  }
  &-circle {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: $white;
    transition: transform 300ms;
  }

  &_disabled {
    cursor: not-allowed;
  }
}

html[dir='rtl'] {
  .switcher {
    &-wrapper {
      &.active {
        .switcher-circle {
          transform: translateX(-20px);
        }
      }
    }
  }
}
