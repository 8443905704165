@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.tooltip {
  position: absolute;
  padding: 15px;
  text-align: center;
  border-radius: 14px;

  &-copied {
    top: -60px;
    right: 0;
    min-width: 170px;
    background-color: $white;
    box-shadow: 0px 6px 20px rgba($dark, 0.08);
  }

  &-information {
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 140px;
    max-width: 355px;
    background-color: rgba($white, 0.95);
    box-shadow: 0px 6px 20px rgba($dark, 0.08);
  }

  &__wrapper {
    position: relative;
    cursor: pointer;
    transition: 0.1s transform ease-in-out 0s;

    &:active {
      transform: scale(1.2);
    }

    &:hover {
      .tooltip_none {
        display: block;
      }
    }
  }

  &__message {
    color: $dark;
    font-size: 14px;
    line-height: 16.8px;
  }

  &_none {
    display: none;
  }
}

.text-wrap-anywhere {
  overflow-wrap: anywhere;
}

html[dir='rtl'] {
  .tooltip-copied {
    right: auto;
    left: 0;
  }
}
