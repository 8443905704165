@import 'taker-web-ui/dist/styles.scss';

.login {
  &__language-switcher {
    position: fixed;
    top: 20px;
    right: 40px;
  }

  &__content {
    width: 100%;
    max-width: 420px;
  }

  &__input-mini {
    min-width: 200px !important;
    width: 200px !important;
    &[disabled] {
      cursor: no-drop;
    }
  }

  &__button-pass-reset {
    min-height: 21px;
    &[disabled] {
      background-color: $graysoft !important;
      cursor: no-drop;
    }
  }
}

html[dir='rtl'] {
  .login__language-switcher {
    right: initial;
    left: 40px;
  }
}

.error-message {
  background: $red;
  border-radius: 12px;
  padding: 8px 15px;

  margin-bottom: 15px;
  width: fit-content;
  bottom: 125px;
}

.logo {
  width: 145px;
  height: 45px;
}

.caps-tooltip {
  position: absolute;
  left: 440px;
  top: 6px;
}
