@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.picker__control {
  min-width: 130px;
  max-width: 250px;
  height: 44px;
  align-content: center;
  border-radius: 12px !important;
  border-color: $gray !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.picker__indicator-separator {
  display: none !important;
}
.picker__dropdown-indicator {
  color: $gray2 !important;
}
.picker__single-value {
  font-family: 'PrimaryMedium' !important;
  font-size: 16px !important;
  line-height: 19.2px !important;
  letter-spacing: 0.02em !important;
  color: $dark !important;
}
.picker__value-container {
  height: 44px;
  padding: 0 20px !important;
}

.picker__menu {
  background-color: $graysoft !important;
  //   padding: 10px;
  box-shadow: 0px 16px 50px rgba($dark, 0.1) !important;
  border-radius: 12px !important;
  border: none !important;
  width: 100% !important;
}

.picker__menu-list {
  padding: 5px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.picker__option {
  border-radius: 10px !important;
  margin-bottom: 1px;
  padding: 13px 20px !important;
  cursor: pointer !important;
  color: $dark !important;
}
.picker__option--is-selected {
  color: $white !important;
  background-color: $mainblue !important;
}
.picker__option--is-focused {
  background-color: $gray;
}

//mini picker style
.picker__control_mini {
  min-width: 170px !important;

  .picker__control {
    height: 36px;
  }

  .picker__single-value {
    font-size: 12px !important;
    line-height: 14.4px !important;
  }

  .picker__value-container {
    height: 36px;
    padding: 0 12px !important;
  }

  .picker__menu {
    font-size: 12px !important;
    line-height: 14.4px !important;
  }

  .picker__option {
    padding: 11px 12px !important;
  }
}

// picker product-list header style
.picker__product-list {
  width: 240px;

  .picker__placeholder {
    font-family: 'PrimaryMedium';
    font-size: 16px;
    line-height: 19.2px;
    color: $dark;
  }

  .picker__option {
    font-family: 'PrimaryMedium';
    font-size: 16px;
    line-height: 19.2px;
  }
}
