.modal-message {
  overflow-y: auto;
  min-width: 400px;
  padding: 5px;
  max-height: 392px;
  border: 5px solid #ffffff;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);

  .modal-message-close {
    top: 10px;
    right: 10px;
    position: absolute;
  }
}
