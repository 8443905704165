.spinner {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.container {
    padding: 40px;
    /* White 80% */

    background: rgba(255, 255, 255, 0.8);
    /* Gray */

    border: 2px solid #dce7f1;
    box-sizing: border-box;
    /* Blur + shadow 2 */

    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(46px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 25px;

    &.cancelable {
      cursor: pointer;

      .spinner-icon {
        font-size: 60px !important;
      }
    }

    .spinner-icon {
      font-size: 50px !important;
      border-width: 1px !important;
      width: 40px !important;
      height: 40px !important;
      stroke-width: 1px;
    }
  }

  &-icon {
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
