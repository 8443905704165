.map-tooltip {
  position: absolute;
  min-width: 260px;
  bottom: calc(100% + 60px);
  left: -95px;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);

  &-option {
    margin-bottom: 5px;

    &-label {
      min-width: 62px;
      white-space: nowrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 8px);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-radius: 3px;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 12px solid rgba(255, 255, 255, 0.6);
  }
}
