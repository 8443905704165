@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.tab-picker {
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &[aria-disabled='true'] {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &-error {
    border: 1px solid $red !important;
  }
  &--tab {
    padding: 13px 15px;
    cursor: pointer;
    border-radius: 10px;
  }
}
