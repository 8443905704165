.time-field {
  min-width: 83px !important;
  height: 46px !important;
  font-family: 'PrimaryMedium';
  font-size: 24px;
  line-height: 28.8px;
  letter-spacing: 0.08em;
  font-weight: 400;
  text-align: center;
  border: none !important;
  background: none !important;
  padding: 0 !important;
}
