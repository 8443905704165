.map-popup_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.map-popup {
  pointer-events: all;
  justify-self: flex-end;
  // flex: 1;
  height: auto;

  /* Blur */
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  backface-visibility: hidden;
  transform: translateY(100%);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px 20px 0px 0px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(80px);
    backface-visibility: hidden;
    height: 100%;
  }

  .arrow {
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s;
    transform: rotate(0deg);
    font-size: 24px;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 92px;
      align-self: center;
      background-repeat: no-repeat;
      background-position: top left;
    }
  }

  &.show {
    transform: translateY(25px);
    transition: 0.2s;
  }

  &.full {
    transform: translateY(0%);

    .arrow {
      transform: rotate(180deg);
    }
  }

  & > div {
    letter-spacing: 0.08em;
  }
}
