.palette {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 4px;
  .color-box {
    margin-bottom: 5px;
    cursor: pointer;
  }
}
