@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.button_old {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  color: #ffffff;
  border: 1px solid;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 14px;
  padding: 18px;
  width: auto;
  position: relative;
  font-family: $font_regular;
  transition: background-color 300ms, color 300ms, opacity 300ms;

  &-outlined {
    border-width: 1px;
    border-style: solid;
  }
  &-medium {
    height: 52px;
    min-height: 52px;
  }

  &-small {
    height: 45px;
    min-height: 45px;
    border-radius: 12px;
    text-align: center;
    font-size: 14px;
    padding: 15px;
  }

  &.full-size {
    width: 100%;
  }

  .button-spinner {
  }

  &[disabled] {
    // background-color: #b6b6b6 !important;
    cursor: no-drop;
    // color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }
}
