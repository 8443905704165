@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.language-picker {
  min-width: 190px !important;

  .picker__control {
    height: 46px;
  }

  .picker__single-value {
    font-size: 16px !important;
    line-height: 19.2px !important;
  }

  .picker__value-container {
    height: 46px;
  }

  .picker__menu {
    font-size: 16px !important;
    line-height: 19.2px !important;
    z-index: 10;
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      min-width: 110px !important;

      .picker__control {
        height: 36px;
      }

      .picker__single-value {
        font-size: 12px !important;
        line-height: 14.4px !important;
      }

      .picker__value-container {
        height: 36px;
        padding: 0 12px !important;
      }

      .picker__menu {
        font-size: 12px !important;
        line-height: 14.4px !important;
        z-index: 10;
      }

      .picker__option {
        padding: 11px 12px !important;
      }
    }
  }
}
