@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.requests-table {
  border-collapse: separate;
  min-width: 100%;
  padding-right: 20px;

  tbody td {
    border-bottom: 20px solid $white;
    border-top: 20px solid $white;
    border-left: 10px solid $white;
    border-right: 10px solid $white;

    &:last-child {
      border-right: 30px solid $white !important;
    }
  }

  thead {
    background-color: $white;
    height: 50px;
  }

  thead th {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: $white;
    border-left: 10px solid $white;
    border-right: 10px solid $white;
  }

  &__empty {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $gray white;

    /* for Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      margin-right: 20px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
      border-radius: 2px;
      background-clip: padding-box;
    }
  }
}

.requests-table__wrapper {
  overflow: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
