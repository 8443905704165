@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

html[dir='rtl'] {
  .input-with-label {
    text-align: left;
  }
}

.input {
  position: relative;
  outline: none;
  color: $dark;
  // border: none;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  border-radius: 16px;

  &-container {
    .error-text {
      margin-top: 10px;
      color: $red;
      font-size: 14px;
    }
  }

  &-wrapper-small {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 45px;
    padding: 0 20px;
    & > input {
      border: none;
      background-color: transparent;
    }
    .side-label {
      position: relative;
      display: inline-flex;
      font-family: 'PrimarySemiBold';
      font-weight: 500;
      font-size: 16px;
      user-select: none;
    }
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    border-color: $gray;
    &.input-error {
      border-color: $red !important;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 60px;
    // border-width: 1px;
    // border-style: solid;
    // border-radius: 12px;
    // border-color: $gray;
    & > input {
      border: none;
      // background-color: transparent;
    }

    .side-label {
      position: relative;
      display: inline-flex;
      font-family: 'PrimarySemiBold';
      font-weight: 500;
      font-size: 16px;
      user-select: none;
    }
  }

  &-with-label {
    min-width: 20px;
    padding: 0px;
    text-align: right;
  }

  &-mini {
    height: 45px;
    border-radius: 12px;
    border-width: 1px;
    min-width: 200px !important;
    width: 200px !important;
  }

  &-error > input {
    border-color: $red !important;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $gray2;
  }

  &-noteditable {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &.border-gray {
    border-width: 2px;
    border-style: solid;
  }
}

.arrowed-tooltip {
  margin: 5px 5px 0 0;
  height: 34px;
  border-radius: 10px;
  box-shadow: 25px 15px 50px -12px $mainblue;

  padding: 10px;

  white-space: nowrap;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: $defaultLetterSpacingAdapted;

  color: $mainblue;
}
