@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.delivery-zone-list {
  backdrop-filter: blur(20px);
  height: 100%;

  &__container {
    height: calc(100% - 48px + 15px);
    // DndContext + custom dnd scroll options + animation = broken layout, if width is not limited
    width: 338px;
  }

  &__scroll {
    overflow-x: hidden;
  }

  &__search {
    border: 1px solid $gray;
    border-radius: 12px;
    position: relative;

    &-icon {
      margin-top: 8px;
    }

    &-input {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 19.2px;
      background-color: $white;
    }
  }
}
