@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.organizations-inputs-wrapper {
  gap: 20px;
  padding-right: 20px;

  .input-search {
    width: 314px;
  }

  .dat-container {
    z-index: 100;
    // border-width: 1px;
    // border-style: solid;
    // border-radius: 12px;
    height: 45px;
    & > .input-wrapper {
      padding: 0 20px;
    }
    &input {
      height: 45px;
    }
  }

  .dat-input-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;

    .input-wrapper {
      width: 382px !important;
    }

    .loader-container {
      width: 24px;
      height: 24px;
    }
  }
}

.organizations-list-controls-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.bulk-status-update-button {
  white-space: nowrap;
  font-family: $font_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  color: #1d1d1d;

  &:not(:last-child) {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.organization-filter-search {
  width: 360px;
}

html[dir='rtl'] {
  .bulk-status-update-button {
    &:not(:last-child) {
      margin-left: 20px;
    }
  }
}

.organization-filter-search-field {
  height: 47px;
  border: 1px solid $gray;
}

.organizations-section {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.blur:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.5;
    content: '';
    z-index: 1;
  }

  & > .loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}
