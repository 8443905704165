@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.dropdown {
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: fixed;
    // display: none;
    visibility: hidden;
    z-index: 110;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($dark, 0.2);

    // background-color: rgba(255, 255, 255, 0.1);
    // transition: graysoft-color 300ms;
  }

  &-modal {
    overflow-y: auto;
    max-height: 392px;
    border: 5px solid $white;
    border-radius: 14px;
    background-color: $white;
    box-shadow: 0px 6px 20px rgba($dark, 0.06);
    position: absolute;

    z-index: 1000;

    visibility: hidden;
    opacity: 0;

    &-bottom {
      right: 0;
      // top: 60px;
      top: calc(100% + 20px);
    }
    &-top {
      right: 0;
      bottom: 60px;
    }
    &-bottom {
      right: 0;
      // top: 60px;
      top: calc(100% + 20px);
    }
    &-right {
      right: calc(100% + 20px);
      top: 0;
    }
    &-left {
      left: 0;
      top: 0;
    }
    &-align-center {
      top: initial;
      bottom: initial;
    }
    &-justify-center {
      left: initial;
      right: initial;
    }
    &-justify-start {
      left: 0;
      right: initial;
    }

    &-justify-end {
      left: initial;
      right: 0;
    }
  }

  &.visible {
    .dropdown-modal {
      visibility: visible;
      opacity: 1;
    }
    &.dropdown-backdrop {
      &::before {
        display: flex;
        visibility: visible;
      }
    }
  }

  &-close {
    position: absolute;
    right: 18px;
    top: 18px;
  }
  // modal opened
  &.active {
    .dropdown-modal {
      animation-name: slide_in_right;
    }
  }
}

html[dir='rtl'] {
  .dropdown {
    &-modal {
      &-justify-start {
        left: initial;
        right: 0;
      }

      &-justify-end {
        left: 0;
        right: initial;
      }
    }
  }
}

.dropdown-modal {
  .modal-pane {
    display: none;
  }
}

@media (max-width: 1023px) {
  .dropdown {
    &.visible {
      &::before {
        display: flex;
        visibility: visible;
      }
    }
    &-modal {
      .info-popup-close {
        display: none;
      }
      .modal-pane {
        display: flex;
      }

      &.popup_in_mobile {
        position: fixed;
        bottom: 10px;
        left: 10px;
        right: 10px;
        top: initial;
        box-shadow: 0px 15px 40px rgba($dark, 0.14);
        border-radius: 20px;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

        padding-top: 34px;
        &.active {
          animation-name: slide_in_down !important;
        }
        &.inactive {
          animation-name: slide_out_down !important;
        }
      }
      // &.modal-bottom-sheet {
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      //   border-radius: 40px 40px 0 0;
      // }
    }
  }
}

html[dir='rtl'] {
  .dropdown {
    &-modal {
      &-top {
        // left: 0;
        // right: initial;
      }
      &-bottom {
        // left: 0;
        // right: initial;
      }
      &-right {
        right: initial;
        left: calc(100% + 20px);
      }
    }

    &-close {
      right: initial;
      left: 18px;
    }
    // modal opened
    &.active {
      .dropdown-modal {
        animation-name: slide_in_right;
      }
    }
  }

  @media (max-width: 1023px) {
    .dropdown {
      &-modal {
        &.popup_in_mobile {
          position: fixed;
          bottom: 10px;
          left: 10px;
          right: 10px;
          top: initial;
          &.active {
            animation-name: slide_in_down !important;
          }
          &.inactive {
            animation-name: slide_out_down !important;
          }
        }
        &.modal-bottom-sheet {
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 40px 40px 0 0;
        }
      }
    }
  }
}
