@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.filter-dropdown-calendar-modal {
  height: initial !important;
  width: 337px !important;
}

.react-calendar {
  padding: 0px 15px 10px 15px;

  &__navigation {
    display: flex;
    &__label {
      margin: 0 5px;
      font-family: 'PrimarySemiBold';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: $defaultLetterSpacingAdapted;
      text-align: center;
      border-radius: 10px;
      flex: 1;
    }

    &__arrow {
      width: 40px;
      height: 40px;
      font-size: 14px;
      line-height: 16.8px;
      border: 1px solid $gray;
      border-radius: 10px;
    }

    &__prev2-button,
    &__next2-button {
      display: none;
    }

    & button:enabled:hover {
      background-color: $gray;
    }
  }

  &__viewContainer {
    margin: 25px 0 0 0;
  }

  &__month-view {
    &__weekdays__weekday {
      // width: 36px;
      max-width: 38px !important;
      height: 36px;
      padding: 10px 0;
      // margin: 0 3px;
      color: $gray2;
      font-family: 'PrimaryMedium';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.12em;
      text-align: center;
      text-transform: uppercase;
    }
    &__days__day {
      //       // width: 36px;
      //       // max-width: 36px !important;
      height: 36px;
      // margin: 3px 3px !important;
      // padding: 9px;
      background: none;
      border: none;
      // flex-basis: 16% !important;

      & > abbr {
        font-family: 'PrimaryMedium';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: $defaultLetterSpacingAdapted;
        text-align: center;
      }

      &--neighboringMonth {
        color: $gray2;
        // opacity: 0;
        // pointer-events: none;
      }

      &--rangeBothEnds {
        background-color: $red !important;
      }
    }
  }

  &__year-view__months__month {
    flex-basis: 24.99% !important;
    height: 90px;
    padding: 0;
    margin: 3px 3px;

    & > abbr {
      font-family: 'PrimaryMedium';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: $defaultLetterSpacingAdapted;
      text-align: center;
    }
  }

  &__decade-view__years__year {
    flex-basis: 22.99% !important;
    width: 75px;
    height: 90px;
    padding: 0;
    margin: 3px 3px;
    font-family: 'PrimaryMedium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: $defaultLetterSpacingAdapted;
    text-align: center;
  }

  // react-calendar__month-view__days {
  //   flex: 1;
  // }

  &__tile {
    // margin: 0 !important;

    &:hover {
      background: #4099f633;
      //
    }

    &--active {
      background-color: $gray;
    }

    &--hasActive {
      border-radius: 10px !important;
      color: $white;
      background-color: $mainblue;
    }

    &--rangeStart,
    &--rangeEnd {
      color: $white !important;
      background-color: $mainblue !important;
    }

    &--rangeEnd {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--now {
      color: $dark;
      background-color: $graysoft;
    }

    &:enabled:hover {
      background-color: $gray;
    }
  }

  &--selectRange &__tile--hover {
    background-color: $gray;
  }
}
