@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.floating-button {
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 15px;

  position: absolute;
  height: 34px;
  right: 20px;
  bottom: 20px;

  /* White 60% */

  background: rgba(255, 255, 255, 0.6);
  /* Blur + shadow 2 */

  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(46px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;

  font-family: $font_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: $defaultLetterSpacingAdapted;

  /* Main */

  color: $mainblue;

  cursor: pointer;
}

html[dir='rtl'] {
  .floating-button {
    left: 20px;
    right: auto;
  }
}
