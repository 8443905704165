@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.dsp-picker {
  min-width: 220px;
  margin-left: 24px;

  .picker__control {
    max-width: 450px;
    height: 46px;
  }

  .picker__placeholder {
    font-family: 'PrimaryMedium';
    font-size: 16px;
    line-height: 19.2px;
    color: $dark;
  }

  .picker__input {
    font-size: 16px;
    line-height: 19.2px;
  }

  .picker__multi-value {
    background-color: inherit;
  }

  .picker__multi-value__label {
    font-size: 12px;
    line-height: 14.4px;
  }

  .picker__single-value {
    font-size: 16px;
    line-height: 19.2px;
  }

  .picker__value-container {
    height: 46px;
  }

  .picker__menu {
    font-size: 16px;
    line-height: 19.2px;
  }

  // .picker__option--is-focused {
  //   background-color: $mainblue;
  // }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    & {
      min-width: 190px;

      .picker__control {
        height: 36px;
      }

      .picker__single-value {
        font-size: 12px !important;
        line-height: 14.4px !important;
      }

      .picker__placeholder {
        font-size: 12px;
        line-height: 14.4px;
      }

      .picker__input {
        font-size: 12px;
        line-height: 14.4px;
      }

      .picker__single-value {
        font-size: 12px;
        line-height: 14.4px;
      }

      .picker__value-container {
        height: 36px;
        padding: 0 12px !important;
      }

      .picker__menu {
        font-size: 12px;
        line-height: 14.4px;
      }

      .picker__option {
        padding: 11px 12px;
      }
    }
  }
}
