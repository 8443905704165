@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.organization-toggles-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 11px;
  grid-row-gap: 10px;
  padding-bottom: 44px;
}

.organization-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $graysoft;
  border-radius: 12px;

  padding: 15px 12px 15px 15px;

  height: 49px;
}
