@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.requests-table-row {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $gray;

  td {
    border-bottom: 1px solid #dce7f1 !important;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 0px !important;
    border-right: 0px !important;
  }

  &:last-child {
    border-bottom: none;
  }
}
