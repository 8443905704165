@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.delivery-zone__map {
  $root: &;
  &-controls {
    #{$root}-zoom {
      position: absolute;
      top: 50%;
      right: 15px;
      width: 48px;

      background: rgba($graysoft, 0.4);
      box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 16px;
      cursor: pointer;
    }
  }
  &-tools {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
  }
  &-control {
    cursor: pointer;
    .color-box {
      transition: 0.4s;
      // border: 1px solid transparent;
      // box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
      // &.active {
      //   border: 1px solid $mainblue;
      // }
    }
  }
}
