@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.sorting-button {
  &__arrow {
    position: relative;
    width: 14px;
    height: 14px;
    color: $gray2;

    &-asc {
      position: absolute;
      top: -2px;
      left: -2px;
      transition: 0.1s transform ease-in-out 0s;

      &_active {
        color: $mainblue;
        transform: scale(1.2);
      }
    }

    &-desc {
      position: absolute;
      top: 1px;
      right: -6px;
      transition: 0.1s transform ease-in-out 0s;
      transform: rotate(180deg);

      &_active {
        color: $mainblue;
        transform: rotate(180deg) scale(1.2);
      }
    }
  }
}

html[dir='rtl'] {
  .sorting-button {
    &__arrow {
      transform: scaleX(-1);

      &-desc {
        // right: 4px;
      }
    }
  }
}
