.map-notifications_container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 0.5s;
  align-items: center;

  & > div {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .map__notification {
    pointer-events: all;
    display: flex;
    flex-direction: row;
    padding: 15px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #dce7f1;
    box-sizing: border-box;
    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(46px);
    border-radius: 18px;

    font-size: 14px;
    letter-spacing: 0.05em;

    .icon {
      margin-right: 15px;
    }

    .content {
      inline-size: 294px;
      overflow-wrap: break-word;
      margin-right: 5px;
    }

    & > * {
      pointer-events: all;
    }
  }
}
