@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
}

*::-webkit-scrollbar-thumb {
  background: rgba(224, 224, 224, 0.7);
  /* Blur */

  backdrop-filter: blur(80px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 3px;
}

.page {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    max-height: 100vh;
    overflow: hidden;
    background-color: $graysoft;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    transition: 300ms;
    width: 100%;
    min-height: 100vh;
    min-width: calc(100% - 70px);
    overflow: hidden;

    &_scale {
      transform: scale(0.9) translateX(-4.5%);
      transition: 700ms;
      border-radius: 40px;
      box-shadow: 0px 10px 20px rgba($mainblue, 0.08);
    }
  }
}

html[dir='rtl'] {
  .page-wrapper_scale {
    transform: scale(0.9) translateX(4.5%);
  }
}
// html[dir='rtl'] {
//   .page-wrapper_scale {
//     @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
//       & {
//         transform: scale(0.9);
//         transition: 700ms;
//       }
//     }
//   }
// }

.router {
  &-wrapper {
    // padding: 30px 20px 20px 20px;
    display: flex;
    max-height: 100vh;
    flex-grow: 1;
    background-color: $white;
  }
}

body {
  background: $white;
  letter-spacing: $defaultLetterSpacing;
}

button {
  letter-spacing: $defaultLetterSpacing;
}

.letter-spacing-0 {
  letter-spacing: 0;
}

.ReactModal__Body--open {
  overflow: hidden; // disable body scrolling when modal is open
}

@media screen and (max-width: $breakpoints_xl) {
  .page {
    &-container {
      background-color: $white;
    }
  }
}

body[aria-mobile='yes'] {
  height: 100vh;
  overflow: hidden;
}
