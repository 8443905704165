@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.customers-modal {
  & .search-customers .input-search {
    height: 44px;
    width: 100%;
    max-width: 360px;
    border: 1px solid $gray;
    border-radius: 12px;
    background-color: $white;
    margin-bottom: 20px;
  }

  & .select-controls {
    display: flex;
    justify-content: space-between;
  }

  & .customers-modal-list {
    margin: 25px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    & .customers-modal-user {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      background: #f7f7f7;
      border-radius: 12px;
      height: 49px;
      font-style: normal;
      letter-spacing: $defaultLetterSpacingAdapted;
      color: #1d1d1d;

      &.active {
        background: #e9f4ff;
        border-radius: 12px;

        & .customers-modal-user-title {
          color: #429eff;
        }
      }

      & .customers-modal-user-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: $defaultLetterSpacingAdapted;
      }
    }
  }

  & .customers-modal-save-btn {
    height: 60px;
    width: 190px;
  }
}
