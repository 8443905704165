@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.custom-picker {
  position: relative;
  display: flex;
  flex-direction: row;

  &_small {
    .custom-picker {
      &__wrapper {
        width: 160px;
        top: 34px;
        border-radius: 12px;
      }

      &__search {
        &-input {
          height: 39px;
          min-width: 160px;
          font-size: 12px;
          line-height: 14.4px;
        }
      }
    }
  }

  &__open {
    &-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      min-width: 240px;
      background-color: $white;
      border: 1px solid $gray;
      border-radius: 12px;
      cursor: pointer;
    }

    &-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 55px;
      cursor: pointer;
    }
  }

  &__placeholder {
    font-family: $font_medium;
    font-size: 16px;
    line-height: 19.2px;
    color: $dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-wrapper-with-reset {
      max-width: calc(100% - 60px);
    }
  }

  &__wrapper {
    width: 275px;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: $white;
    box-shadow: 0px 16px 50px rgba($dark, 0.1);
    backdrop-filter: blur(46px);
    border-radius: 16px;
    overflow: hidden;
    z-index: 2;
  }

  &__search {
    &-input {
      height: 53px;
      font-family: $font_semibold;
      font-size: 16px;
      line-height: 19.2px;
      color: $dark;
      background-color: $white;
      border-radius: initial;
    }

    &-text {
      font-family: $font_medium;
      font-size: 16px;
      line-height: 19.2px;
      color: $gray2;
      margin-left: 5px;
    }
  }

  &__overlay {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    z-index: 1;
  }

  &__icon {
    width: 30px !important;

    &-wrapper {
      height: 24px;
      width: 24px;
      background-color: $graysoft;
      border-radius: 6px;
    }

    &-reset {
      z-index: 3;
    }
  }

  &__empty-list {
    font-family: $font_semibold;
    font-size: 16px;
    line-height: 19.2px;
    color: $dark;
  }

  &__validation-block {
    margin: 10px 0 0 0;
    font-family: $font_regular;
    font-size: 14px;
    line-height: 16.8px;
    color: $red;
  }

  // used in reports
  &_from-media {
    .custom-picker {
      @media (max-height: 700px) {
        &__wrapper {
          max-height: 265px !important;
        }
      }

      @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
        &__open-input {
          width: 215px;
          height: 40px;
        }

        &__wrapper {
          width: 215px;
          top: 50px;
        }

        &__search-text {
          font-size: 12px !important;
        }

        &__placeholder {
          font-size: 12px;
        }

        &__search-input {
          height: 47px;
        }

        &__empty-list {
          font-size: 12px;
        }
      }
    }

    .picker-option {
      @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
        &__text {
          font-size: 12px;
          line-height: 14.4px;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .custom-picker {
    &__wrapper {
      left: initial;
      right: 0;
    }
  }
}
