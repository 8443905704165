@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.order-info-modal {
  max-width: 1010px;
  max-height: 708px;

  .height-min {
    height: min-content;
  }

  .info-blocks {
    & > div {
      align-items: flex-start;
    }
  }
}

.popup-timers {
  display: flex;
  justify-content: flex-end;

  b {
    margin: 0;
  }

  b:first-child {
    margin-right: 15px;
  }
}

html[dir='rtl'] {
  .info-blocks {
    & > div {
      align-items: flex-end;
      text-align: end;
    }
  }

  .popup-timers {
    b {
      margin: 0;
    }

    b:first-child {
      margin-left: 15px;
    }
  }
}
