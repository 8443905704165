.message-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-content {
    overflow-y: auto;
    min-width: 340px;
    padding: 8px;
    max-height: 392px;
    border: 5px solid #ffffff;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
    position: absolute;

    .modal-message-close {
      top: 16px;
      right: 16px;
      position: absolute;
    }
  }
}
