@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.time-picker {
  &__times {
    height: 46px;
    max-height: 46px;
    position: relative;
    border-radius: 10px;
    background-color: $graysoft;
  }

  &__slots {
    height: 46px;
    max-height: 46px;
    padding: 5.5px 0;
    position: relative;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
  }

  // &__time {
  //   padding: 3.5px 0;
  //   text-align: center;
  // }

  &_disabled {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.6;
  }
}
