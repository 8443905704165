@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.order-header {
  position: relative;
  width: 100%;
  min-height: 76px;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgba(66, 158, 255, 0.4);
  background-color: $mainblue;

  &_history {
    box-shadow: none;
    background-color: $graysoft;

    & .order-header__order-id {
      color: $dark;
    }

    & .order-header__horbar {
      border-top: 1px solid $gray2;
    }

    & .order-header__order-status {
      color: $dark;
    }
  }

  &__gift-block {
    height: 36px;
    width: 36px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgba($white, 0.2);
  }

  &__message {
    min-width: 325px;
    letter-spacing: $defaultLetterSpacingAdapted;
    text-align: left;
  }

  &__order-id {
    color: $white;
    font-size: 22px;
    line-height: 26.4px;
    letter-spacing: 0.08em;
  }

  &__order-status {
    color: $white;
    font-size: 20px;
    line-height: 24px;
  }

  &__horbar {
    width: 26px;
    height: 1px;
    border: none;
    border-top: 1px solid $white;
  }

  @media (min-width: $breakpoints_md) and (max-width: $breakpoints_xl) {
    &__horbar {
      width: 20px;
      height: 1px;
      border: none;
      border-top: 1px solid $white;
    }

    &__order-id {
      font-size: 20px;
      line-height: 24px;
    }

    &__order-status {
      font-size: 18px;
      line-height: 21.6px;
    }
  }
}
