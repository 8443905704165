@import 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';

.time-periods {
  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $gray5;
    margin: 5px 0 10px 0;
  }
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
  }
}
